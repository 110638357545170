import React from 'react';
import { Header } from '../Header/Header';
import { About } from '../About/About';
import { Skills } from '../Skills/Skills';
import { WorkList } from '../WorkList/WorkList';
import { Education } from '../Education/Education';
import { Footer } from '../Footer/Footer';

export class Home extends React.Component {
  render (){
    return (
      <div>
	      <Header />
	      <main>
					<About />
					<Skills />
					<WorkList showModal={this.props.showModal} workItems={this.props.workItems} feedModal={this.props.feedModal} /> 
					<Education />
				</main>
				<Footer quote={this.props.quote} randomizeQuote={this.props.randomizeQuote} />
			</div>
    );
  }
}