import React from 'react';

export class WorkBlock extends React.Component {
	constructor(props){
		super(props);

		this.feedModal = this.feedModal.bind(this);
	}

	feedModal(project, workId, projectId){
		this.props.feedModal(project, workId, projectId);
	}

  render (){
    return (
    	<div>
      	<h3>{this.props.companyName} {this.props.years}</h3>
      	<p>{this.props.workDescription}</p>
      	<br />
          <ul className="work">
          	{
							this.props.projects.map((project, index) => {
								return (
									<li key={project.id}>
										<img src={`/works/`+project.imgThumbnail} alt={project.title} onClick={ () => {this.feedModal(project, this.props.workId, index); this.props.showModal()} } />
									</li>
								);
							})
        		}
      	</ul>
      </div>
    );
  }
};