import React from 'react';
import logo from './np-logo-small.png';
import { NavHashLink as NavLink } from 'react-router-hash-link';

export class Navigation extends React.Component {
	render (){
		const scrollWithOffset = (el) => {
			const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
			const yOffset = -100; 
			window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 

			if (this.props.isNavOpen) {
				this.props.toggleMobileNav();
			}
		}

		return (
		<nav>
			<div className="nocturnal-logo">
				<a href="/" rel="noopener noreferrer"><img src={logo} className="logo" alt="logo" /></a>
			</div>
			<div className="nocturnal-links">
				<div className="nocturnal-hamburger" onClick={this.props.toggleMobileNav}>☰</div>
				<div className={ this.props.isNavOpen ? "nocturnal-nav-links mobile-open" : "nocturnal-nav-links" }>
			        <ul>
			        	<li><NavLink to="/#about" title="About" scroll={scrollWithOffset}>About</NavLink></li>
			        	<li><NavLink to="/#skills" title="Skills" scroll={scrollWithOffset}>Skills</NavLink></li>
			        	<li><NavLink to="/#education" title="Education" scroll={scrollWithOffset}>Education</NavLink></li>
			        	<li><NavLink to="/#work" title="Work" scroll={scrollWithOffset}>Work</NavLink></li>
			        	<li><a href="mailto:rafaelmejia@gmail.com?subject=nocturnalpixel" title="Contact Rafael Mejia">Contact</a></li>
			        	<li><a className="nav-link" href="/other/rafael_mejia_resume_2022.pdf" target="_blank" title="Download Resume">Download Resume</a></li>
			        </ul>
			    </div>
			</div>
	    </nav>
		);
	}
}
