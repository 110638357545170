import React from 'react';

export class Header extends React.Component {
	render (){
		return (
			<header>
		  	<h1>My name is Rafael Mejia,<br />and I make things while you sleep.</h1>
		  </header>
		);
	}
}