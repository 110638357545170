import React from 'react';

export class Footer extends React.Component {
	render (){
		return (
			<footer>
		    <blockquote>
		    	<div className="tv" key={this.props.quote.id} onClick={this.props.randomizeQuote} >
		    		<div className="tv__top">
		    			<div className="tv__top__antenna">
		    				<div className="tv__top__antenna--left"></div>
		    				<div className="tv__top__antenna--right"></div>
		    			</div>
		    		</div>
		    		<div className="tv__screen">
							<div className="phrase">{this.props.quote.quote}</div>
							<div className="by">- {this.props.quote.by}</div>
							<div className="pixel"></div>
						</div>
					</div>
		  	</blockquote>
        <section className="copyright">
         	All content copyright <a href="http://nocturnalpixel.com/">NocturnalPixel</a> © {new Date().getFullYear()} • All rights reserved.
        </section>
		  </footer>
		);
	}
}