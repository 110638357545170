import React from 'react';

export class Education extends React.Component {
render (){
  return (
    <section id="education" className="nocturnal-education">
      <h2>Education</h2>
      <ul>
        <li>
          <div>
            <div className="title">Master of Fine Arts in Communication Design and Technology</div>
            <div>Parsons School of Design - New York, NY</div>
            <div>Graduation: May 2007</div>
        
            <div className="nocturnal-smaller awards">
              <div>
                <div className="sub-title">Awards</div>
                <div>Best Visual Design - Mobile Game Mosh Competition 2006.</div>
                <div>Team Name: Oh My Mosh!</div>
                <div>Position: Programmer.</div>
                <div>Software: Game Maker.</div>
              </div>
              <div>
                <div className="sub-title">Exhibitions</div>
                <div>Parsons The New School for Design 10th Annual Thesis Exhibition.</div>
                <div>Location: The Chelsea Art Museum, New York.</div>
                <div>Project Title: El Rompecabezas: An Interactive Puzzle Installation.</div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div>
            <div className="title">Bachelor's Degree in Computer Sciences</div>
            <div>Universidad de Puerto Rico - Bayamon, PR</div>
            <div>Graduation: 2004</div>
          </div>
        </li>
      </ul>
  </section>
  );
}
}