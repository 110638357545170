import React from 'react';
import socialTwitter from './twitter-64.png';
import socialInstagram from './instagram-64.png';
import socialPinterest from './pinterest-64.png';
import socialLinkedIn from './linkedin-64.png';

export class About extends React.Component {
    render (){
        return (
        <div id="about" className="nocturnal-intro">
            <div className="nocturnal-intro-desc">
                <h2>About Me</h2>
                <p>Hi, I'm Rafael Mejia, a Senior Web Developer specializing in multiple web technologies such as HTML, CSS, Javascript (including some React), and PHP. I've had over 15 years of experience in web development. I also co-founded and led as main developer for Brands &amp; Things, a design and advertisement agency, providing services such as web design/development, print, film, and social media.</p>
                <p>Nerd at heart! I'm into graphic novels, adventure games, puzzles, and social media. Sucker for great, simple, and clean design. Apple enthusiast. Lego builder. I collect records, pins, posters, and I'm obsessed with The Office.</p>
                <p>Be Excellent To Each Other (And Party On, Dudes).</p>
                <div className="resume"></div>
            </div>
            <ul className="nocturnal-social">
                <li>
                    <a href="https://twitter.com/rafalevelsup" title="Twitter" target="_blank" rel="noopener noreferrer"><img className="icon-twitter full-img" src={socialTwitter} alt="twitter" /></a>
                </li>
                <li>
                    <a href="https://instagram.com/rafalevelsup" title="Instagram" target="_blank" rel="noopener noreferrer"><img className="icon-instagram full-img" src={socialInstagram} alt="instagram" /></a>
                </li>
                <li>
                    <a href="https://www.pinterest.com/rafalevelsup/" title="Pinterest" target="_blank" rel="noopener noreferrer"><img className="icon-pinterest full-img" src={socialPinterest} alt="pinterest" /></a>
                </li>
                <li>
                    <a href="https://www.linkedin.com/pub/rafael-mejia/4/9b2/848" title="Linkedin" target="_blank" rel="noopener noreferrer"><img className="icon-linkedin full-img" src={socialLinkedIn} alt="linkedin" /></a>
                </li>
            </ul>
        </div>
        );
    }
}