import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Navigation } from '../Navigation/Navigation';
import { Home } from '../Home/Home';
import { Modal } from "../Modal/Modal";
import workItems from '../WorkList/work.json';
import quotes from '../Footer/quotes.json';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default class App extends React.Component {

  constructor(props){
    super(props);
    
    this.quoteId = Math.floor( (Math.random() * quotes.length));

    this.state = {
      isNavOpen: false,
      show: false,
      project: [],
      workId: false,
      thisProjectId: false,
      previousProjectId: false,
      nextProjectId: false,
      quote: { 
        "id": this.quoteId,
        "quote": quotes[this.quoteId].quote,
        "by": quotes[this.quoteId].by
      }
    };

    this.targetElement = document.querySelector('body');

    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.feedModal = this.feedModal.bind(this);
    this.updateModal = this.updateModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.randomizeQuote = this.randomizeQuote.bind(this);
  }

  toggleMobileNav = () => {
    this.setState({ isNavOpen: !this.state.isNavOpen });
  }

  feedModal = (project, workId, thisId) => {
    this.setState({ project: project });
    this.setState({ workId: workId });
    this.setState({ thisProjectId: thisId });
    this.setState({ previousProjectId: (!thisId) ? false : thisId - 1 });
    this.setState({ nextProjectId: (thisId + 1 === workItems[workId].projects.length) ? false : thisId + 1 });
  }

  showModal = () => {
    this.setState({ show: true });
    disableBodyScroll(this.targetElement);
  }

  updateModal = thisId => {
    if (this.state.workId >= 0){
      let project = workItems[this.state.workId].projects[thisId];
      this.feedModal(project, this.state.workId, thisId);
    }
  }

  hideModal = () => {
    this.setState({ project: [] });
    this.setState({ show: false });
    enableBodyScroll(this.targetElement);
  }

  randomizeQuote = () => {
    let newId;
    do {
      newId = Math.floor( (Math.random() * quotes.length) );
    } while (this.state.quote.id === newId);
    
    this.setState({
      quote: {
        "id": newId,
        "quote": quotes[newId].quote,
        "by": quotes[newId].by
      }
    });
  }

  render(){
    return (
      <Router>
        <div className="App">
          <Navigation isNavOpen={this.state.isNavOpen} toggleMobileNav={this.toggleMobileNav} />
          <Modal 
            show={this.state.show}
            hideModal={this.hideModal}
            project={this.state.project}
            updateModal={this.updateModal}
            thisProjectId={this.state.thisProjectId}
            previousProjectId={this.state.previousProjectId}
            nextProjectId={this.state.nextProjectId} />
          <Switch>
            <Route exact path="/" render={(props) =>
              <Home
                show={this.state.show}
                showModal={this.showModal}
                workItems={workItems}
                feedModal={this.feedModal}
                quote={this.state.quote}
                randomizeQuote={this.randomizeQuote} 
              />
            }/>
            <Route>{'404'}</Route>
          </Switch>
        </div>
      </Router>
    );
  }
}