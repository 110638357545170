import React from 'react';

export class Skills extends React.Component {
    render (){
        return (
            <section id="skills" className="nocturnal-skills">
                <h2>Web Development</h2>
                <ul>
                    <li>HTML5, CSS, SCSS, Javascript, React, jQuery, PHP, Twig, Bootstrap</li>
                    <li>Github, BitBucket, SVN</li>
                    <li>Node, Gulp, Homebrew, Lando, Docker, Jekyll, Command Line, Grunt, MAMP, XAMPP, Vagrant</li>
                    <li>CMS/Frameworks: Wordpress, Laravel, MODx, Zend, Grav (flat-file)</li>
                    <li>Wordpress development: Advanced Custom Fields, Custom plugins, Shortcodes, Widgets, Redirection, Sitemaps</li>
                    <li>DB: MySQL, NoSQL, MS SQL, MongoDB</li>
                    <li>CRM/Cloud/Hosting: Github, Jamstack, Netlify, Cloudinary, Contentful, WPEngine, Pantheon, Ghost, Rackspace</li>
                    <li>Salesforce: Lead management, Lightning Experience (Livechat implementation)</li>
                    <li>Project Management Tools: Jira, Asana, TargetProcess, Redmine, Trello, Redbooth</li>
                    <li>Marketing: GTM, OneTrust, Sailthru, Socialflow, Chartbeat</li>
                    <li>Design Tools: Figma, Invision, Google Web Designer, Adobe CS Suite, Sketch</li>
                    <li>Code Editors: SublimeText, VS Code</li>
                    <li>MVC Architectural Pattern, Agile environment, Scrum workflow</li>
                    <li>SEO awareness</li>
                    <li>Clean and simple design</li>
                </ul>

                <h2>Team Lead and Project Management</h2>
                <ul>
                    <li>Team Lead for the WebCore Team at Complex Media</li>
                    <li>Remote management of a small development team from Argentina (ElPixel)</li>
                    <li>Agile methodologies, Sprint development cycles, and daily scrum meetings</li>
                </ul>

                <h2>Other skills &amp; interests</h2>
                <ul>
                    <li>Past experience with: C++, MS SQL, Processing, AppleScript, Paypal API, After Effects, Processing, xcode</li>
                    <li>Game Design</li>
                    <li>Toy Design</li>
                    <li>Fully bilingual: Spanish/English</li>
                </ul>
            </section>
        );
    }
}