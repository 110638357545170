import React from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import arrowLeftThin from './arrow-left-thin.svg';
import arrowRightThin from './arrow-right-thin.svg';
import iconClose from './x-mark-thin.svg';

export class Modal extends React.Component {
	constructor(props){
		super(props);

		this.handleClick = this.handleClick.bind(this);
		this.navFunction = this.navFunction.bind(this);
		this.navigatePrevious = this.navigatePrevious.bind(this);
		this.navigateNext = this.navigateNext.bind(this);
	}
	componentDidMount(){
		document.addEventListener('mousedown', this.handleClick, false);
		document.addEventListener("keydown", this.navFunction, false);
		this.targetElement = document.querySelector('#modal');
	}
	componentWillUnmount(){
		document.removeEventListener('mousedown', this.handleClick, false);
		document.removeEventListener("keydown", this.navFunction, false);
		clearAllBodyScrollLocks();
	}

	handleClick = (e) => {
		if(this.modal.contains(e.target)) {
			return;
		}

		this.props.hideModal();
	}

	navFunction(e) {

		if(!this.props.show) {
			return;
		}

    if(e.keyCode === 27) {
      this.props.hideModal();
    }
    if(e.keyCode === 37 && this.props.thisProjectId > 0) {
      this.navigatePrevious();
    }
    if(e.keyCode === 39 && this.props.nextProjectId) {
      this.navigateNext();
    }
	}

	navigatePrevious = ()  => {
		this.props.updateModal(this.props.previousProjectId);
	}

	navigateNext = ()  => {
		this.props.updateModal(this.props.nextProjectId);	
	}

	render(){
		return (
		    <div id="modal" className={this.props.show ? "modal display-on" : "modal display-none"}>
	      	<div className="modal-internal" ref={modal => this.modal = modal}>
		      	<div className="modal-image">
		      		{ typeof this.props.project.img == 'string' && this.props.project.img.length > 0 && 
		      			<img src={`/works/`+this.props.project.img} alt={this.props.project.title} />
		      		}
		      	</div>
		      	<div className="modal-info">
			      	<div className="modal-nav-buttons">
		            { this.props.thisProjectId > 0 && <button type="button" onClick={this.navigatePrevious} className="arrow-left"><img src={arrowLeftThin} alt="Previous Project" /></button> }
		            { this.props.nextProjectId && <button type="button" onClick={this.navigateNext} className="arrow-right"><img src={arrowRightThin} alt="Next Project" /></button> }
		          </div>
		      		<div className="title">{this.props.project.title}</div>
		      		<div className="years">{this.props.project.years}</div>
		      		
		      		<div className="description">
	                <div dangerouslySetInnerHTML={ { __html: this.props.project.description } } />
	                <div className="links">
	                	<ul>
	                		{ (typeof this.props.project.url == 'string' && this.props.project.url.length > 0) || (typeof this.props.project.url == 'object') ? <li>Links:</li> : '' }
	                		{typeof this.props.project.url == 'string' && this.props.project.url.length > 0 ? <li><a href={this.props.project.url} target="_blank" title={this.props.project.title} rel="noopener noreferrer">{this.props.project.title}</a></li> : '' }
	                		{typeof this.props.project.url == 'object' ? this.props.project.url.map((urls) => { return (<li><a href={urls.url} target="_blank" title={urls.name} rel="noopener noreferrer">{urls.name}</a></li>) }) : '' }
	                	</ul>
	                </div>
	            </div>
		      	</div>
		      	<button type="button" className="back-button" onClick={this.props.hideModal}><img src={iconClose} alt="Close Window" /></button>
					</div>
		    </div>
		);
	}
};