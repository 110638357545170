import React from 'react';
import { WorkBlock } from './WorkBlock.js';

export class WorkList extends React.Component {
  render (){
    return (
      <section id="work" className="nocturnal-work">
      	<h2>Stuff I've worked on</h2>
        {
          this.props.workItems.map((project, index) => {
            return <WorkBlock key={project.companyName} companyName={project.companyName} workId={index} workDescription={project.workDescription} years={project.years} projects={project.projects} showModal={this.props.showModal} feedModal={this.props.feedModal} />
          })
				}
      </section>
  	);
  }
}